"use strict";
import FiltersModel from '../_filters/model.js';
import mapBoxGl from 'mapbox-gl';
import { round } from 'lodash';
import Swiper, { Navigation , Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// update the query string based on the selected filters
const updateQueryString = FiltersModel.updateQueryString;

export default class MapView {

    static currentCenter = {};
    static loading_new_ads = false;
    static infinity_scroll_current_page = 1;
    static done = false;
    static map = null; // Map object
    static MoveEndEventTimer = null; // Timer for the moveend event on the map
    static isPropertiesSourceUpdatedViaFilter = false; // flag to check if the properties source is updated via filter
    static adsInfo = null; // ads info for the map
    static realEstateAdIds = null; // real estate ad ids for the special markers
    static boundaries = null; // boundaries for the map
    static isSpecialMarkerClicked = false; // flag to check if the special marker is clicked
    
    // load ads in the search listing results page and the map
    static loadAds(url, page = 1 , clear, callback , geoPoints = true) {    
        //update the current query in the form and the url
        const $form = $('#search-page-filters');
        const actionUrl = $form.attr('action');
        var queryString = updateQueryString($form);
    
        $form.data('query', queryString);
        $('#show-list a').attr('href', actionUrl + '?' + queryString);
        queryString += '&map_view=true';
        window.history.pushState('', '', `?${queryString}`); // update the url
    
        if(MapView.boundaries !== null){
            if(typeof MapView.boundaries === 'object') MapView.boundaries = JSON.stringify(MapView.boundaries);
            queryString += `&boundaries=${MapView.boundaries}`;
        }

        if(MapView.realEstateAdIds !== null){
            queryString += `&real_estate_ids=${MapView.realEstateAdIds}`;
        }
    
        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: url,
            type: 'POST',
            data: queryString + `&page=${page}&geo_points=${geoPoints}` ,
            dataType: 'json',
            context: this,
            beforeSend: function () {
                if (clear) {
                    $('#realestate-container').html('');
                }
                $('#realestate-container').append(`
                <div role="status" class="loader mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
                    <svg aria-hidden="true" class="w-16 h-16 mr-2 text-gray-200 animate-spin fill-blue-brand" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                `);
            },
            success: function (response) {
                callback(response);
            }
        });
    }
    
    static getAds(page = 1, isFocused = true , geoPoints = true) {
    
        // console.log('focus state is '+ isFocused );
    
        MapView.done = false;
        MapView.loading_new_ads = false;
        MapView.infinity_scroll_current_page = 1;
        const getAdsUrl = $('#listing').data('get-ads');
        $('.mapboxgl-popup, .mapboxgl-marker').remove();

        // reset the clicked special markers
        if(MapView.realEstateAdIds === null){
            MapView.map.setPaintProperty('special-unclustered-points', 'circle-color', '#2C5364');
            MapView.map.setPaintProperty('special-unclustered-point-count', 'text-color', '#ffffff');
            MapView.isSpecialMarkerClicked = false;
        }
    
        MapView.loadAds(getAdsUrl, page, true, (response) => {
            const $criteria = $('#criteria').find('ul'); // alert criteria container
            const $realEstateContainer = $('#realestate-container');
            const $alertForm = $('.alert-form');
            const noAdsMessage = `
                <div role="status" class="mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-blue-brand text-center">
                            <p class="fa-solid fa-4x fa-circle-exclamation"></p>
                            <p class="text-xl mt-1">
                                Aucune annonce ne correspond à vos critères pour le moment
                            </p>
                </div>
            `;
            
            // update the alert criteria
            $criteria.html('');
            if (response.alert_criteria.length > 0) {
                $.each(response.alert_criteria, function (_, value) {
                    $criteria.append(`
                        <li class="inline-block mr-2 bg-[#F2F4F6] p-3 rounded-full mb-2 capitalize">${value}</li>
                    `)
                });
            }

            // update the alert form
            $alertForm.find('#ad_type_alert').val(response.filters.ad_type || 'sell');
            $alertForm.find('#realestate_type_alert').val(JSON.stringify(response.filters.realestate_type || ''));
            $alertForm.find('#locations_alert').val(JSON.stringify(response.filters.locations || ''));
            $alertForm.find('#rooms_alert').val(response.filters.rooms || '');
            $alertForm.find('#bedrooms_alert').val(response.filters.bedrooms || '');
            $alertForm.find('#price_min_alert').val(response.filters.price_min || '');
            $alertForm.find('#price_max_alert').val(response.filters.price_max || '');
            $alertForm.find('#space_min_alert').val(response.filters.space_min || '');
            $alertForm.find('#space_max_alert').val(response.filters.space_max || '');
            $alertForm.find('#facility_alert').val(JSON.stringify(response.filters.facility || ''));
            $alertForm.find('#is_first_owner_alert').val(response.filters.is_first_owner || '0');
            $alertForm.find('#keywords_alert').val(JSON.stringify(response.filters.keywords || ''));
            $alertForm.find('#energy_class_alert').val(JSON.stringify(response.filters.energy_class || ''));
            $alertForm.find('#listed_by_alert').val(response.filters.listed_by || '');

    
            // update the search title
            $('#listing-title p').text(response.title);
    
            if (response.html.length === 0) {
                $realEstateContainer
                    .html('')
                    .append(noAdsMessage);

                if(response.geoPoints) 
                    MapView.removeMapCluster();

                return;
            }
    
            $realEstateContainer
                .html('')
                .append(response.html);

            if(response.geoPoints){
                const {specialGeoLocations , popupAds, geoLocations } = response;
                MapView.createMapCluster({specialGeoLocations , geoLocations}, isFocused);
                MapView.adsInfo = popupAds;

                // Add the popup and marker to each ad
                MapView.adsInfo.forEach(ad => {
                    ad.popup = new mapBoxGl.Popup({ 
                        offset: 25 ,
                        closeButton: false,
                        closeOnClick: false
                    })
                        .setLngLat([ad.long, ad.lat])
                        .setHTML(ad.html);
        
                    const $markerElement = $('<img class="marker" src="/assets/mon_blue_icon_small.png" />');
                    ad.marker =  new mapBoxGl.Marker({
                        element: $markerElement[0],
                    })
                    .setLngLat([ad.long, ad.lat]);
                });
            }

            //set the height of #realestate-container
            const headerNavLength = $('.header-nav').outerHeight();
            const filtersFormLength = $('.map-view').outerHeight();
            const listingTitleLength = $('#listing-title').outerHeight(true);

            // set the initial height of the real estates list container 
            let containerHeight = $(window).height() - 
            (headerNavLength + 
            filtersFormLength +
            listingTitleLength);

            containerHeight = round(containerHeight, 0);

            $realEstateContainer.height(containerHeight - 10);
            $realEstateContainer.parent().height(containerHeight - 10);

             // Initialize Swiper for each card
             $('.swiper-container').each(function (_, element) {
                const swiperId = $(element).attr('id'); // Get the unique swiper ID        

                // Initialize a new Swiper instance for each container
                new Swiper('#' + swiperId, {
                    loop: true,
                    slidesPerView: 1,
                    modules: [ Navigation, Pagination ],
                    pagination: {
                        el: '#' + swiperId + ' .swiper-pagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    navigation: {
                        nextEl: '#ad-' + swiperId + ' .custom-button-next',
                        prevEl: '#ad-' + swiperId + ' .custom-button-prev',
                    },
                    on: {
                        navigationNext: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                        navigationPrev: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                    },
                });
            });
            
        } , geoPoints);
    }
    
    // update the source data for the map
    static updateSourceData(data){
        if (!MapView.map.isStyleLoaded()) {
            // If the style is not loaded yet, wait and retry
            window.setTimeout(() => MapView.updateSourceData(data), 200);
            return;
        }
        const propertiesSource = MapView.map.getSource('properties');
        if (propertiesSource) {
            propertiesSource.setData(data);
    
        } else {
           console.log('Source not found.');
        }
    }
    
    // create/update the map cluster for the search results
    static createMapCluster(data , isFocused) {
        const {specialGeoLocations , geoLocations } = data;
        let features = [];
    
        if(MapView.map === null) return;
    
        if (geoLocations.length == 0 && specialGeoLocations.length == 0) {
            MapView.removeMapCluster();
            return;
        };
    
        // initialize clusters
        for (let geoLocation of geoLocations) {
            features.push({
                'id': geoLocation.id,
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        parseFloat(geoLocation.lang),
                        parseFloat(geoLocation.lat)
                    ]
                },
                'properties': {
                    'link': geoLocation.link,
                    'count': 0,
                    'is_special': false,
                }
            });
        }

        for (let location of specialGeoLocations) {
            features.push({
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        parseFloat(location.rounded_longitude),
                        parseFloat(location.rounded_latitude)
                    ]
                },
                'properties': {
                    'ids': location.ids,
                    'count': location.location_count,
                    'is_special': true,
                }
            });
        }

        const jeoJsonData = {
            "type": "FeatureCollection",
            "features": features
        };

        MapView.updateSourceData(jeoJsonData);
    
        //zoom in to center point of the combined clusters when the map is focused 
        if(isFocused){
            MapView.isPropertiesSourceUpdatedViaFilter = true;
            // console.log('passed');
            // set cluster bounds
            let clusterBounds = jeoJsonData.features.reduce(function (bounds, feature) {
                if (!Array.isArray(feature.geometry.coordinates[0])) {
                    return bounds.extend(feature.geometry.coordinates);
                } else {
                    return feature.geometry.coordinates.reduce(function (bounds, coord) {
                        return bounds.extend(coord);
                    }, bounds);
                }
            }, new mapBoxGl.LngLatBounds());
        
            MapView.currentCenter = clusterBounds;
        
            MapView.map.fitBounds(MapView.currentCenter, {
                maxZoom: 12,
                padding: 30
            });
        }
                
    }

    // remove the map cluster and markers
    static removeMapCluster() {
        const propertiesSource = MapView.map.getSource('properties');
        const data = {
            "type": "FeatureCollection",
            "features": []
        }
        if (propertiesSource) {
            propertiesSource.setData(data);
        }
    }
    
    // update the map when the map is moved or zoomed
    static updateOnMoveEnd(){
        // console.log('updateOnMoveEnd');
        if (MapView.MoveEndEventTimer) clearTimeout(MapView.MoveEndEventTimer);
        
        const bounds = MapView.map.getBounds();
        
        // Southwest corner of the map boundary
        const sw = bounds.getSouthWest();
    
        // Northeast corner of the map boundary
        const ne = bounds.getNorthEast();
    
        MapView.boundaries = {
            'sw': { 
                'lat': sw.lat,
                'long': sw.lng
            },
            'ne': {
                'lat': ne.lat,
                'long': ne.lng
            }
        };
    
        //to not call the getAds function when the properties source is updated via filter and the map is focused
        if(MapView.isPropertiesSourceUpdatedViaFilter){
            MapView.isPropertiesSourceUpdatedViaFilter = false;
            return;
        }

        // reset the clicked special markers
        MapView.realEstateAdIds = null;
        MapView.isSpecialMarkerClicked = false;
        MapView.map.setPaintProperty('special-unclustered-points', 'circle-color', '#2C5364');
        MapView.map.setPaintProperty('special-unclustered-point-count', 'text-color', '#ffffff');

        // console.log('move end event fired');
        //to prevent the loadAds function from being called on the map load
        MapView.MoveEndEventTimer = setTimeout( () => {
            // Update the ads list based on the map boundaries
            MapView.getAds(1, false , false);
        }, 1000);
    }
}